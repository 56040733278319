import useGetCalls, {
  getNumberOfPages,
  getCallVariables,
} from '../api/useGetCalls'
import useGetCallsCount from '../api/useGetCallsCount'

const useCalls = (page: number, limit?: number) => {
  const { skip, limit: defaultLimit } = getCallVariables(page)
  const { data } = useGetCalls({ skip, limit: limit ?? defaultLimit })
  const { data: callsCountData } = useGetCallsCount()
  const calls = data?.callCollection?.items ?? []

  const numberOfPages = getNumberOfPages(
    callsCountData?.callCollection.total ?? 0
  )

  return {
    calls,
    numberOfPages,
  }
}

export default useCalls

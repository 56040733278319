import Screen from './Screen'
import Pagination from './Pagination'
import useCalls from '../hooks/useCalls'
import Call from './Call'

const Calls = ({ uri, page = '1', ...rest }: PagePropsPagination) => {
  const parsedPage = parseInt(page)
  const { calls, numberOfPages } = useCalls(parsedPage)

  return (
    <Screen uri={uri} {...rest}>
      <h2 className="h2-medium divider pb-4 mb-4">Výjezdy</h2>
      {calls.map(({ title, date, sys: { id }, content }, index) => (
        <Call
          title={title}
          date={date}
          key={id}
          content={content}
          isLast={index === calls.length - 1}
        />
      ))}
      <Pagination
        numberOfPages={numberOfPages}
        page={parsedPage}
        type="calls"
      />
    </Screen>
  )
}

export default Calls

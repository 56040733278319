import { useQuery, gql } from '@apollo/client'

interface CallCollectionQueryData {
  callCollection: {
    total: number
  }
}

const QUERY = gql`
  query {
    callCollection {
      total
    }
  }
`
const useGetCallsCount = () => useQuery<CallCollectionQueryData>(QUERY)

export default useGetCallsCount

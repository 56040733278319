import { Router } from '@reach/router'
import Calls from '../components/Calls'

const Vyjezdy = () => {
  return (
    <Router>
      <Calls {...({ path: '/vyjezdy/:page' } as PagePropsPagination)} />
      <Calls {...({ path: '/vyjezdy/' } as PagePropsPagination)} />
    </Router>
  )
}
export default Vyjezdy

import { format } from 'date-fns'
import cn from 'classnames'

interface Props {
  title: string
  date: string
  content: string
  isLast: boolean
}

const Call = ({ title, date, content, isLast }: Props) => {
  const formattedDate = format(new Date(date), 'dd.MM.yyyy')
  return (
    <div className={cn({ 'mb-4 pb-4 divider': !isLast })}>
      <h3 className="p16-medium-main m-0 mb-2">{title}</h3>
      <p className="p16 m-0 mb-2">
        <strong>{formattedDate}</strong>
      </p>
      <p className="p16 m-0 !leading-5">{content}</p>
    </div>
  )
}

export default Call

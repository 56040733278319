import { useQuery, gql } from '@apollo/client'

export interface Call {
  sys: {
    id: string
  }
  title: string
  date: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any
}

interface CallCollectionQueryVars {
  limit: number
  skip: number
}

interface CallCollectionQueryData {
  callCollection?: {
    items: Call[]
  }
}

const CALLS_PER_PAGE = 8
export const getCallVariables = (page: number) => {
  const skip = (page - 1) * CALLS_PER_PAGE
  return {
    skip,
    limit: CALLS_PER_PAGE,
  }
}
export const getNumberOfPages = (numberOfGalleries: number) =>
  Math.ceil(numberOfGalleries / CALLS_PER_PAGE)

const QUERY = gql`
  query CallCollection($limit: Int!, $skip: Int!) {
    callCollection(limit: $limit, skip: $skip, order: date_DESC) {
      items {
        sys {
          id
        }
        title
        date
        content
      }
    }
  }
`

const useGetCalls = ({ skip, limit }: CallCollectionQueryVars) =>
  useQuery<CallCollectionQueryData, CallCollectionQueryVars>(QUERY, {
    variables: { skip, limit },
  })

export default useGetCalls
